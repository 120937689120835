<template>
  <div>
    <div class="flex">
      <v-container>
        <div class="px-4 py-15">
          <div class="text-start">
            <a class="text-decoration-underline blue--text mt-5" @click="$router.push(managementPage)">登録ユーザ管理ページへ</a>
          </div>
          <v-row class="pt-10">
            <v-col cols="12">
              <v-skeleton-loader
                  v-if="this.loader"
                  type="table"
              ></v-skeleton-loader>
              <v-simple-table v-else>
                <template v-slot:default>
                  <thead style="background-color: #f5f5f5">
                  <tr>
                    <th>
                      <v-checkbox :color="allUserCheckboxColor" label="全選択" style="font-size: 12px"
                                  v-model="allUser"
                                  readonly
                                  @click="check"
                                  :indeterminate="!allUser&&userArray.length>0"
                                  hide-details></v-checkbox>
                    </th>
                    <th class="text-left">
                      氏名
                    </th>
                    <th class="text-left">
                      初回登録日
                    </th>
                    <th class="text-center">SkillsMap</th>
                    <th class="text-center">ExMap</th>
                    <th></th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr style="line-height: 20px" v-for="info in listData" v-bind:key="info.user_id">
                    <td>
                      <v-checkbox color="orange" :value=info.account_id v-model="userArray" hide-details></v-checkbox>
                    </td>
                    <td class="text-left">{{ info.last_name + " " + info.first_name }}</td>
                    <td class="text-left">{{ getDate(info.registered_on) }}</td>
                    <td class="text-center">
                      <v-icon v-if="info.number_of_skills_map===0" left>mdi-minus</v-icon>
                      <v-icon v-if="info.number_of_skills_map===1" left color="info">mdi-file-document-box-outline
                      </v-icon>
                      <v-icon v-if="info.number_of_skills_map>1" left color="success">
                        mdi-file-document-box-multiple-outline
                      </v-icon>
                    </td>
                    <td class="text-center">
                      <v-icon v-if="info.number_of_exp_map===0" left>mdi-minus</v-icon>
                      <v-icon v-if="info.number_of_exp_map===1" left color="info">mdi-file-document-box-outline</v-icon>
                      <v-icon v-if="info.number_of_exp_map>1" left color="success">
                        mdi-file-document-box-multiple-outline
                      </v-icon>
                    </td>
                    <td class="mt-3">
                      <v-btn class="px-8 py-4 float-right" small rounded :outlined="true" color="light-blue darken-2"
                             @click="gotoDetails(info.account_id)">
                        <div class="subtitle-1 font-weight-bold">詳細表示</div>
                      </v-btn>
                    </td>
                  </tr>

                  </tbody>
                </template>
              </v-simple-table>
              <div class="text-center my-5">
                <v-pagination
                    v-model="page"
                    :length="totalPage"
                ></v-pagination>
              </div>
              <div class="text-left mt-5">
                <p style="display: inline" class="text-start mr-5 caption">
                  <v-icon left color="info">mdi-file-document-box-outline</v-icon>
                  1プロジェクトのみ
                </p>
                <p style="display: inline" class="text-start caption">
                  <v-icon left color="success">mdi-file-document-box-multiple-outline</v-icon>
                  複数プロジェクト平均点
                </p>
              </div>

            </v-col>
          </v-row>
          <p class="mt-10 mb-7">選択したユーザの最新マップを一括ダウンロードできます。</p>
          <v-row no-gutters justify="center">
          <BaButton btn-text="ダウンロード" :outlined="false" :disabled=downloadStatus btnIcon="mdi-download-outline"
                    @click="downloadSelected()"/>
          <BaButton class="float-left ml-5" btn-text="コメント付きでダウンロード" :disabled=downloadStatus :outlined="false" @click="downloadSelectedwithComment"
                    btnIcon="mdi-download-outline"/>
          </v-row>
        </div>
      </v-container>
    </div>
  </div>
</template>

<script>
import BaButton from "@/components/BaButton";
import store from "@/store";
import routeNames from "@/router/routes/routeNames";
import axios from "axios";
import api from "@/helper/api";
import S3Client from "@/helper/S3Client";
export default {
  name: "UserList",
  components: {BaButton},
  data() {
    return {
      loader: true,
      listData: null,
      userArray: [],
      paginatedUserArray: [],
      downloadStatus: true,
      totalPage: null,
      allUser: false,
      managementPage: routeNames.USER_MANAGEMENT.path,
    }
  },
  computed: {
    page: {
      get() {
        return parseInt(this.$route.query.page)
      },
      set(value) {
        this.$router.replace({
          name: routeNames.USER_LIST.name, query: {...this.$route.query, page: value}
        })
      }
    },
    allUserCheckboxColor() {
      if (this.allUser) {
        return 'orange'
      } else {
        return null
      }
    },
    listedUserIds() {
      let arr = []
      if (this.listData) {
        for (let i = 0; i < this.listData.length; i++) {
          arr.push(this.listData[i].account_id)
        }
      }
      return arr
    },
  },
  methods: {
    check() {
      if (!this.allUser && this.userArray.length > 0) {
        this.userArray = []
      } else {
        this.allUser = !this.allUser
        if (this.allUser) {
          this.userArray = this.listedUserIds
        } else {
          this.userArray = []
        }
      }
    },
    downloadSelected() {
      this.$loading(true)
      axios.post(
          api.DOWNLOAD_PDF,
          JSON.stringify({
            account_id: this.userArray,
            keys: []
          })).then((res) => {
        window.location.assign(res.data.public_url)
        let s3Client = new S3Client(res.data.key)
        setTimeout(function () {
          s3Client.deleteFile()
        }, 3000)
      }).catch(err => {
        this.$store.dispatch('updateDialog', err.response.data.error)
      }).finally(() => {
        this.$loading(false)
      })
    },
    getDate(date) {
      let d = new Date(date);
      return d.getFullYear() + "年" + (d.getMonth() + 1) + "月" + d.getDate() + "日"
    },
    loadUserList(page) {
      axios.get(api.GET_USER_LIST + page)
          .then((res) => {
            this.allUser = false
            this.listData = res.data.user_list
            this.totalPage = res.data.meta.total_page
          }).catch(err => {
        this.$store.dispatch('updateDialog', err.response.data.error)
      }).finally(() => {
        this.loader = false
      })
    },
    gotoDetails(accountID) {
      let name = routeNames.USER_DETAILS.name
      let url = {
        name: name,
        params: {
          account_id: accountID
        }
      }
      this.$router.push(url)
    },
    arrDiff(arr1, arr2) {
      return arr1
          .filter(x => !arr2.includes(x))
          .concat(arr2.filter(x => !arr1.includes(x)))
    },
    downloadSelectedwithComment(){
      axios.post(api.ADMIN_DOWNLOAD_COMMENT_PDF_USERSET,
          JSON.stringify({
        account_id: this.userArray})
      ).then((response) => {
            let url = {
              name: routeNames.COMMENT_PDF_ZIP.name,
              params: {
                data:response.data,
                user_list_data: {
                  paginated_user_array:this.paginatedUserArray,
                  page:parseInt(this.$route.query.page),
                  user_array:this.userArray,
                  all_user:this.allUser
                }
              }
            }
            this.$router.replace(url);
      });
    },

  },
  created() {
    this.$store.dispatch('normalLd');
    if (!this.page || this.page < 1) {
      this.$router.replace({
        name: routeNames.USER_LIST.name, query: {...this.$route.query, page: 1}
      })
    } else {
      this.loadUserList(this.page)
    }
    if(this.$route.params.associate_data){
     this.allUser=this.$route.params.associate_data.all_user;
     this.userArray=this.$route.params.associate_data.user_array;
     this.paginatedUserArray=this.$route.params.associate_data.paginated_user_array;
    }
    this.$loading(false);
  },
  watch: {
    userArray: function (val) {
      this.downloadStatus = val.length <= 0;
      this.allUser = this.arrDiff(this.listedUserIds, val).length < 1;
    },
    listData() {
      this.allUser = this.arrDiff(this.listedUserIds, this.userArray).length < 1;
    },
    page(val, prevVal) {
      this.loader = true
      this.paginatedUserArray[prevVal] = this.userArray
      if (this.paginatedUserArray[val]) {
        this.userArray = this.paginatedUserArray[val]
      } else {
        this.userArray = []
      }
      this.loadUserList(val)
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.getters.loggedIn) {
      next({
        name: routeNames.LOGIN.name,
      });
    } else {
      next();
    }
  },


}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .container {
    max-width: 1064px !important;
  }
}

.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}

.v-label {
  font-size: 5px;
}
</style>
