<template>
  <v-snackbar
      v-model="show"
      multi-line
      :timeout="null"
  >
    {{ message }}

    <template v-slot:action="{ attrs }">
      <v-btn
          color="red"
          text
          v-bind="attrs"
          @click="show = false"
      >
        閉じる
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "GlobalSnackBar",
  data() {
    return {
      show: false,
      message: null
    }
  },
  created() {
    if (this.snackMessage) {
      this.message = this.snackMessage
      this.show = true
    }
  },
  computed: {
    snackMessage() {
      return this.$store.getters.getSnack
    }
  },
  watch: {
    snackMessage(val) {
      if (val) {
        this.message = val
        this.show = true
      }
    },
    show(val) {
      if (val) {
        this.$store.commit('setSnack', null)
      }
    },
  }
}
</script>

<style scoped>

</style>