<template>
  <div class="loader d-flex align-center justify-center" style="overflow-x: hidden;">
    <v-col
      class="mx-15"
    >
      <p class="loading ma-0 font-weight-bold text-caption text-left">Status: {{statusText}}</p>
      <v-progress-linear
          height="20px"
          class="font-weight-bold"
          :buffer-value='BufferValue'
          :value='ProgressValue'
          stream
          color="orange"
      ></v-progress-linear>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "Progressbar",
  props:{
    progressBar: Object
  },
  computed:{
    ProgressValue(){
      return this.progressBar.progressValue;
    },
    BufferValue(){
      return this.progressBar.bufferValue;
    },
    statusText(){
      return this.progressBar.statusTxt;
    }
  }

}
</script>

<style lang="scss" scoped>
.loader{
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: fixed;
}
/* loading dots */

.loading:after {
  content: '.';
  animation: dots 500ms steps(5, end) infinite;}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
        .75em 0 0 rgba(0,0,0,0),
        1.5em 0 0 rgba(0,0,0,0);}
  40% {
    color: #2c3e50;
    text-shadow:
        .75em 0 0 rgba(0,0,0,0),
        1.5em 0 0 rgba(0,0,0,0);}
  60% {
    text-shadow:
        .75em 0 0 #2c3e50,
        1.5em 0 0 rgba(0,0,0,0);}
  80%, 100% {
    text-shadow:
        .75em 0 0 #2c3e50,
        1.5em 0 0 #2c3e50;}}
</style>