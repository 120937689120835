<template>
  <div>
    <div class="flex">
      <v-container>
        <CommentDialog :accountId="accountId"
                       :keysExtend="keysExtend"
                       :commentDialog="commentDialog"
                       :commentBtnColor="commentBtnColor"
                       :commentBtnTxt="commentBtnTxt"
                       @update-comments-number="updateCommentNumber"
                       @hide-comments="hideComments"></CommentDialog>

        <div class="px-4 py-15">
          <div class="text-start">
            <a class="text-decoration-underline blue--text mt-5" @click="$router.push(managementPage)">登録ユーザ管理ページへ</a>
          </div>
          <v-row class="pt-10">
            <v-col cols="12">

              <v-simple-table class="mb-8">
                <template v-slot:default>
                  <thead style="background-color: #f5f5f5">
                  <tr>
                    <th class="text-left">
                      氏名
                    </th>
                    <th class="text-left">
                      初回登録日
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="line-height: 20px">
                    <td class="text-left">
                      {{ fullName }}
                    </td>
                    <td class="text-left">
                      {{ registeredDate }}
                    </td>
                  </tr>


                  </tbody>
                </template>
              </v-simple-table>

            </v-col>
          </v-row>

          <v-row>
            <v-col cols="6">
              <p class="l-subtitle text-start mb-8 font-weight-bold">スキルマップ履歴</p>
              <v-skeleton-loader
                  v-if="this.loader"
                  type="table-cell"
              ></v-skeleton-loader>
              <v-row v-else align="center" class="pa-0 ma-0" v-for="info in loadedData.skill_map_results"
                     v-bind:key="info.id">
                <v-checkbox  :disabled="!info.key"
                             v-model="keysExtend"
                             color="orange"
                             :value="JSON.stringify({key:info.key, skill_map: info.id, exp_map:null})"
                             :label="formatStyle(info.date)+'実施'"
                            hide-details></v-checkbox>
                <v-icon v-if="info.key" class="mt-5 ml-3" color="blue" @click="viewPDF(info.key)">mdi-file-document-box-search-outline
                </v-icon>
                <v-icon v-else class="mt-5 ml-3" color="red" @click="showKeyError">
                  mdi-information-outline
                </v-icon>

                <v-badge
                    v-if="info.key"
                    :content="getNumberofComment(info.number_of_comments)"
                    color="red"
                    overlap
                    offset-x="10"
                    offset-y="30"
                >
                  <v-icon class="mt-5 ml-3" color="blue" @click="showComments(info.key,info.id,1,info.has_comments)">
                    mdi-message-text-outline
                  </v-icon>
                </v-badge>
              </v-row>
            </v-col>
            <v-col cols="6">
              <p class="l-subtitle text-start mb-8 font-weight-bold">エクスペリエンスマップ履歴</p>
              <v-skeleton-loader
                  v-if="this.loader"
                  type="table-cell"
              ></v-skeleton-loader>
              <v-row v-else align="center" no-gutters v-for="info in loadedData.exp_map_results" v-bind:key="info.id">
                <v-checkbox v-if="info.is_average" v-model="keysExtend"   :value="JSON.stringify({key:info.key, skill_map: null, exp_map:null})" hide-details
                            :disabled="!info.key" color="#19BF0E"
                            :label="formatStyle(info.date)+'[avg]実施'"></v-checkbox>
                <v-checkbox v-else v-model="keysExtend" color="orange" :value="JSON.stringify({key:info.key, skill_map: null, exp_map:info.id})" hide-details :disabled="!info.key"
                            :label="formatStyle(info.date)+'['+info.project_name+']実施'"></v-checkbox>
                <v-icon v-if="info.key" class="mt-5 ml-3" color="blue" @click="viewPDF(info.key)">
                  mdi-file-document-box-search-outline
                </v-icon>
                <v-icon v-else class="mt-5 ml-3" color="red" @click="showKeyError">
                  mdi-information-outline
                </v-icon>
                <v-badge
                    v-if="!info.is_average && info.key!=null"
                    :content="getNumberofComment(info.number_of_comments)"
                    color="red"
                    overlap
                    offset-x="10"
                    offset-y="30"
                >
                  <v-icon  class="mt-5 ml-3" color="blue" @click="showComments(info.key,info.id,2,info.has_comments)">
                    mdi-message-text-outline
                  </v-icon>
                </v-badge>
              </v-row>
            </v-col>
          </v-row>
          <p class="mt-10 mb-7">選択したマップを一括ダウンロードできます。</p>
          <v-row no-gutters justify="center">
          <BaButton btn-text="ダウンロード" :outlined="false" :disabled="downloadStatus" btnIcon="mdi-download-outline"
                    @click="downloadSelected"/>
          <BaButton class="float-left ml-5" btn-text="コメント付きでダウンロード"  :disabled="downloadCommentStatus" :outlined="false" @click="downloadSelectedwithComment"
                    btnIcon="mdi-download-outline"/>
          </v-row>
        </div>

      </v-container>
    </div>
  </div>
</template>

<script>
import BaButton from "@/components/BaButton";
import store from "@/store";
import routeNames from "@/router/routes/routeNames";
import axios from "axios";
import api from "@/helper/api";
import S3Client from "@/helper/S3Client";
import CommentDialog from  "@/components/Dialogs/CommentDialog";
export default {
  name: "UserDetails",
  components: {CommentDialog, BaButton},
  data() {
    return {
      loader: true,
      loadedData: [],
      keys: [],
      managementPage: routeNames.USER_MANAGEMENT.path,
      downloadStatus: true,
      downloadCommentStatus:true,
      commentDialog:{
        show:false,
        url:null,
        comments: [],
        loading: true,
        map_id: null,
        map_type:null,
        is_addable: null,
        is_editable: null,
        is_deletable: null,
        has_comments:null,
      },
      commentBtnColor:"light-blue darken-2",
      commentBtnTxt:"コメントを保存",
      skillMapIds:[],
      expMapIds:[],
      keysExtend:[],
      accountId:null,

    }
  },
  methods: {
    showKeyError() {
      this.$store.dispatch('updateDialog', "このマップは、生成が中断されたので、ダウンロードできません。")
    },
    downloadSelected() {
      this.$loading(true)
      axios.post(
          api.DOWNLOAD_PDF,
          JSON.stringify({
            account_id: [],
            keys: this.keys
          })).then((res) => {
        window.location.assign(res.data.public_url)
        let s3Client = new S3Client(res.data.key)
        setTimeout(function () {
          s3Client.deleteFile()
        }, 3000)
      }).catch(err => {
        this.$store.dispatch('updateDialog', err.response.data.error)
      }).finally(() => {
        this.$loading(false)
      })
    },
    loadUserDetails(accountID) {
      axios.post(api.GET_USER_DETAILS, {account_id: accountID})
          .then((res) => {
            this.loader = false
            this.loadedData = res.data
          }).catch(err => {
        this.$store.dispatch('updateDialog', err.response.data.error)
      }).finally(() => {
        this.loader = false
      })
    },
    formatStyle(date) {
      return date.replaceAll("-", ".")
    },
    viewPDF(key) {
      let s3Client = new S3Client(key)
      window.open(s3Client.getUrl())
    },
    showComments(key,id,type,has_comments){

      this.commentBtnColor="light-blue darken-2";
      this.commentBtnTxt="コメントを保存";
      this.commentDialog.show=true
      let s3Client = new S3Client(key)
      this.commentDialog.url=s3Client.getUrl()+"#toolbar=0&navpanes=0&scrollbar=0"
      axios.post(api.GET_ADMIN_COMMENT_LIST, {map_id: id,map_type:type})
          .then((response) => {
            this.commentDialog.comments=response.data.comment_list
            this.commentDialog.map_id=id
            this.commentDialog.map_type=type
            this.commentDialog.is_addable = response.data.is_addable
            this.commentDialog.is_editable = response.data.is_editable
            this.commentDialog.is_deletable = response.data.is_deletable
            this.commentDialog.comments=response.data.comment_list;
            this.commentDialog.map_id=id;
            this.commentDialog.map_type=type;
            this.commentDialog.key=key;
            this.commentDialog.has_comments=has_comments;
          })
          .catch((error) => {
            this.$store.commit('setDialog', error)
          })
    },
    hideComments(){
      this.commentDialog.show=false;
      this.commentDialog.url=null;
      this.commentDialog.comments= [];
      this.commentDialog.loading= true;
      this.commentDialog.map_id= null;
      this.commentDialog.map_type=null;
      this.commentDialog.is_addable= null;
      this.commentDialog.is_editable= null;
      this.commentDialog.is_deletable= null;
      this.commentDialog.key=null;
      this.commentDialog.has_comments=null;
    },
    updateCommentNumber(val){
        if(val.map_type==2){
          for(let i=0;i<this.loadedData.exp_map_results.length;i++){
            if(this.loadedData.exp_map_results[i].id==val.map_id){
              this.loadedData.exp_map_results[i].number_of_comments=val.number_of_comments;
               if(val.number_of_comments==0){
                   this.loadedData.exp_map_results[i].has_comments=false;
               }
            }
          }
        }
        else if(val.map_type==1){
          for(let i=0;i<this.loadedData.skill_map_results.length;i++){
            if(this.loadedData.skill_map_results[i].id==val.map_id){
              this.loadedData.skill_map_results[i].number_of_comments=val.number_of_comments;
              if(val.number_of_comments==0){
                this.loadedData.skill_map_results[i].has_comments=false;
              }
            }
          }
      }
    },
    getNumberofComment(num){
      if(num==0)
        return "0";
      else
        return num;
    },
    downloadSelectedwithComment(){
      axios.post(api.ADMIN_DOWNLOAD_COMMENT_PDF_MAPSET,
          JSON.stringify({
            account_id: this.accountId,
            skill_map_id:this.skillMapIds,
            exp_map_id: this.expMapIds
          })
      ).then((response) => {
        let temp=response.data.user_account_list;
        response.data.user_account_list=[];
        response.data.user_account_list.push(temp);
        let url = {
          name: routeNames.COMMENT_PDF_ZIP.name,
          params: {
            user_details_data:{
              keys_extend: this.keysExtend ,
              account_id:this.accountId,
            },
            data: response.data
          }
        }
        this.$router.replace(url);
      }).catch(err => {
        this.$store.dispatch('updateDialog', err.response.data.error);
      });
    },
  },
  computed: {
    fullName() {
      return this.loadedData.last_name + " " + this.loadedData.first_name
    },
    registeredDate() {
      let d = new Date(this.loadedData.registered_on);
      return d.getFullYear() + "年" + (d.getMonth() + 1) + "月" + d.getDate() + "日"
    },
  },
  created() {
    this.$loading(true);
    this.$store.dispatch('normalLd');
    if(this.$route.params.account_id){
      this.accountId=this.$route.params.account_id;
      this.loadUserDetails(this.accountId);
    }
    if(this.$route.params.associate_data){
        this.accountId=this.$route.params.associate_data.account_id;
        this.loadUserDetails(this.accountId);
        this.keysExtend=this.$route.params.associate_data.keys_extend;
    }
    if(this.$route.params.comment_dialog){
      this.commentDialog=this.$route.params.comment_dialog;
    }
    this.$loading(false);
  },
  watch: {
    keys: function (val) {
      this.downloadStatus = val.length <= 0;
    },
    keysExtend:function (val){
      let keyArr=[];
      let sMapArr=[];
      let expMapArr=[];
      val.forEach((objStr)=>{
        let tuple =JSON.parse(objStr);
        keyArr.push(tuple.key);
        if(tuple.skill_map){
          sMapArr.push(tuple.skill_map);
        }
        if(tuple.exp_map){
          expMapArr.push(tuple.exp_map);
        }
      });
      this.keys=keyArr;
      this.skillMapIds=sMapArr;
      this.expMapIds=expMapArr;
      this.downloadCommentStatus = !(this.skillMapIds.length > 0 || this.expMapIds.length > 0);
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.getters.loggedIn) {
      next({
        name: routeNames.LOGIN.name,
      })
    } else {
      if (to.params.account_id || to.params.associate_data) {
        next();
      }
      else {
        next({
          name: routeNames.USER_MANAGEMENT.name,
        })
      }
    }
  }

}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .container {
    max-width: 1064px !important;
  }
}
.l-subtitle {
  font-size: 14px;
  padding: 4px 16px 0px 16px;
  border-left: 4px solid #FBC02D;
}
</style>
