import axios from "axios";
import api from "@/helper/api";


const state = {
    token: null,
    refresh_token: null,
    admin_type: null,
    company_name: null,
}

const getters = {
    loggedIn(state) {
        return state.token !== null;
    },
    getToken(state) {
        return state.token
    },
    getRefreshToken(state) {
        return state.refresh_token
    },
    isSuperAdmin(state) {
        return state.admin_type <= 1;
    },
    getCompanyName(state) {
        return state.company_name
    }
}

const mutations = {
    setToken(state, token) {
        state.token = token;
    },
    setRefreshToken(state, token) {
        state.refresh_token = token
    },
    setAdminType(state, type) {
        state.admin_type = type
    },
    setCompanyName(state, name) {
        state.company_name = name
    },

}

const actions = {
    login(context, credential) {
        return new Promise((resolve, reject) => {
            axios.post(api.LOGIN, {
                user_name: credential.username,
                password: credential.password
            })
                .then((response) => {
                    context.commit('setToken', response.data.access_token)
                    context.commit('setRefreshToken', response.data.refresh_token)
                    context.commit('setAdminType', response.data.admin_type_id)
                    context.commit('setCompanyName',response.data.company_name)
                    resolve()
                })
                .catch((error) => {
                    reject(error.response.data.error)
                })
        })
    },
    logout(context) {
        return new Promise(((resolve, reject) => {
            axios.post(api.LOGOUT, {
                refresh_token: context.getters.getRefreshToken,
            })
                .then(() => {
                    context.commit('setToken', null)
                    context.commit('setRefreshToken', null)
                    context.commit('setCompanyName', null)
                    context.commit('setAdminType', null)
                    resolve()
                })
                .catch((error) => {
                    reject(error.response.data.error)
                })
        }))
    },
    refreshToken(context) {
        return new Promise(((resolve, reject) => {
            axios.post(api.REFRESH, {
                refresh_token: context.getters.getRefreshToken,
            })
                .then((response) => {
                    context.commit('setToken', response.data.access_token)
                    context.commit('setRefreshToken', response.data.refresh_token)
                    resolve(response.data.access_token)
                })
                .catch((error) => {
                    reject(error.response.data.error)
                })
        }))
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}