import routeNames from "@/router/routes/routeNames";
import Login from "@/views/Login";
import Error from "@/views/Error";

const routes = [
    {
        path: routeNames.LOGIN.path,
        name: routeNames.LOGIN.name,
        component: Login
    },
    {
        path: routeNames.ERROR.path,
        name: routeNames.ERROR.name,
        component: Error
    },

]
export default routes.map(route => {
    const meta = {
        public: true,
        title: route.name
    }
    return { ...route, meta }
})