const state = {
    snack: null,
}

const getters = {
    getSnack(state) {
        return state.snack
    },
}

const mutations = {
    setSnack(state, snack) {
        state.snack = snack
    },
}

const actions = {
    createSnack(context, snack) {
        context.commit('setSnack', snack)
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}