import CustomLd from "@/components/Loaders/CustomLd";

const state = {
    ldOptions: {
        text: 'Loading',
        background: 'rgb(255,255,255,0.8)',
    }
}

const getters = {
    getLdOptions(state) {
        return state.ldOptions
    }
}

const mutations = {
    setLdOptions(state, option) {
        state.ldOptions = option
    }
}

const actions = {
    normalLd(context) {
        context.commit('setLdOptions', {
            text: 'Loading',
            background: 'rgb(255,255,255,0.8)',
            loading: false,
        })
    },
    resultGenerateLd(context) {
        let vm = CustomLd
        context.commit('setLdOptions', {
            text: "Generating Result",
            background: 'rgb(255,255,255,255)',
            customLoader: vm,
        })
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}