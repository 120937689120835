<template>
  <v-dialog
      v-model="show"
      width="500"
  >
    <v-card>
      <v-card-title
          class="headline mb-4"
          primary-title
      >
        <v-img
            alt="Logo"
            class="shrink"
            contain
            width="130"
            :src="require('@/assets/skillsscan-logo.svg')"
        />
      </v-card-title>

      <v-card-text>
        {{ message }}
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="yellow darken-4"
            text
            @click="show = false"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GlobalDialog",
  data() {
    return {
      show: false,
      message: null
    }
  },
  created() {
    if (this.dialogMessage) {
      this.message = this.dialogMessage
      this.show = true
    }
  },
  computed: {
    dialogMessage() {
      return this.$store.getters.getDialog
    }
  },
  watch: {
    dialogMessage(val) {
      if (val) {
        this.message = val
        this.show = true
      }
    },
    show(val) {
      if (val) {
        this.$store.commit('setDialog', null)
      }
    },
  }
}
</script>

<style scoped>

</style>