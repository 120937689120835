import Vue from 'vue'
import Vuex from 'vuex'
import account from "@/store/modules/account";
import dialogs from "@/store/modules/dialogs";
import snacks from "@/store/modules/snacks";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import routeParams from "@/store/modules/routeParams";
import ldOptions from "@/store/modules/ldOptions";
Vue.use(Vuex)

let ls = new SecureLS({isCompression: true});
let encryptionOption = {}
if (process.env.VUE_APP_ENV === 'production') {
    encryptionOption = {
        storage: {
            getItem: (key) => ls.get(key),
            setItem: (key, value) => ls.set(key, value),
            removeItem: (key) => ls.remove(key),
        }
    }
}

export default new Vuex.Store({
    modules: {
        account,
        dialogs,
        snacks,
        routeParams,
        ldOptions
    },
    plugins: [
        createPersistedState(encryptionOption),
    ],
})
