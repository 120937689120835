const state = {
    dialog: null
}

const getters = {
    getDialog(state) {
        return state.dialog
    }
}

const mutations = {
    setDialog(state, msg) {
        state.dialog = msg
    }
}

const actions = {
    updateDialog(context, msg) {
        context.commit('setDialog', msg)
    },
}

export default {
    state,
    getters,
    mutations,
    actions
}