export default {
    LOGIN:{
        name: 'Login - Skills-Scan',
        path: '/login'
    },
    USER_MANAGEMENT:{
        name: "Registered User Management - Skills-Scan",
        path: "/"
    },
    USER_LIST:{
        name: "Registered User List - Skills-Scan",
        path: "/list"
    }
    ,
    USER_DETAILS:{
        name: "Registered User Details - Skills-Scan",
        path: "/details"
    },
    ERROR:{
        name: "Not Found - Skills-Scan",
        path: "*"
    },
    COMMENT_PDF:{
        name: "Comment PDF - Skills-Scan",
        path: "/comment/pdf"
    },
    COMMENT_PDF_ZIP:{
        name: "Comment PDF ZIP - Skills-Scan",
        path: "/comment/pdf/zip"
    },
}