<template>
  <v-container fluid ref="comment" class="ma-auto pa-0 shrink" style="width: 1245px; height: 1890px;" >
    <!--    Header Start-->
    <v-sheet
        class="pa-0 mx-0 flex-column d-flex justify-center"
        color="#F5F5F5"
        height="128px"
        width="100%"
    >
      <v-container
          class="px-0 px-sm-0 px-md-0"
      >
        <v-row
            no-gutters
            class="mx-15"
        >
          <v-col cols="auto">
            <img
                alt="skills-scan_logo"
                width="250"
                height="44"
                src="../assets/skillsscan-logo.svg"
                @load="barImages.headerImage=true"
            />
          </v-col>
          <v-col cols="auto" class="px-6 mr-6 font-weight-bold flex-column d-flex justify-center">
            コメント
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="font-weight-medium flex-column d-flex justify-center subtitle-1 font-weight-black">
            <v-row no-gutters
                   class="pa-0 ma-0 align-center "
            >
              回答者 : {{ getName }}
            </v-row>
            <v-row no-gutters
                   class="pa-0 ma-0 align-start"
            >
              回答日時 : {{ currentDateTime }}
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!--    Header End-->
    <div class="pa-0 ma-0" ref="commentList">
      <!--    Comment Start -->
      <v-row  class="mx-15" v-for="comment in comments" :key="comment.comment_id+comment.created_by" no-gutters justify="center">
        <v-card
            width="90%"
            flat
        >
          <v-card-subtitle v-if="comment.created_by!=null" class="text-start font-weight-bold text-sm-h5">{{ comment.created_by }} :</v-card-subtitle>
          <v-card-subtitle v-if="comment.created_by!=null && comment.edited_by==null" class="mt-n5 text-end font-weight-medium">{{ dateTimeFormater(comment.created_at) }}</v-card-subtitle>
          <v-card-subtitle  v-if="comment.created_by!=null && comment.edited_by!=null" class="mt-n5 text-end font-weight-medium">{{comment.edited_by }} に {{ dateTimeFormater(comment.edited_at)}} に編集されました  {{ dateTimeFormater(comment.created_at) }}</v-card-subtitle>
          <v-card-text class="pre-formatted text-start font-weight-medium">{{  comment.comment }}</v-card-text>
        </v-card>
      </v-row>
    </div>
    <!--    Comment End-->
    <!--    Footer text Start-->
    <v-footer
        absolute
        class="mt-10"
        color="#F5F5F5"
    >
      <v-container style="width: 1247px;">

        <v-row class="mx-15 pb-2 pt-2">
          <v-col cols="auto" class="text-left">
            <img
                alt="ba_logo"
                :src="require('@/assets/BA-Logo_BW.png')"
                height="44"
                width="58"
                @load="barImages.footerImage=true"
            />
          </v-col>
          <v-col md="col-auto" class="text-left text-sm-left text-md-right pt-6 black--text">
            © {{ new Date().getFullYear() }} <span>Business Architects Inc</span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <!--    Footer End-->

  </v-container>
</template>
<script>
import {jsPDF} from "jspdf";
import { PDFDocument } from 'pdf-lib';
import routeNames from "@/router/routes/routeNames";
import store from "@/store";
import { saveAs } from 'file-saver';
export default {
  name: "CommentPdfDownload",
  inject:["progressBar"],
  data() {
    return {
      barImages: {
        headerImage: false,
        footerImage: false,
        data: false,
      },
      comments:[],
      pages:[],
      size:0,
      pdfBuffer:[],
      commentDialog: null,
      userDetailsData:{},
    }
  },
  computed:{
    currentDateTime() {
      let dateTime = new Date()
      return dateTime.getFullYear() + "/" +
          (dateTime.getMonth() + 1).toString().padStart(2, '0') + "/" +
          dateTime.getDate().toString().padStart(2, '0') + " " +
          dateTime.getHours().toString().padStart(2, '0') + ":" +
          dateTime.getMinutes().toString().padStart(2, '0');
    },
    getName() {
      return "Admin"
    },
  },
  updated() {
    this.size=this.$refs.commentList.clientHeight;
  },
  methods:{
    async populateHeight(){
      this.size=this.$refs.commentList.clientHeight;
    },
    async segmentPage(){
      this.progressBar.statusTxt="Processing comments";
      this.progressBar.bufferValue= this.progressBar.bufferValue+70;
      let perCommentProcessProgressValue=70/(this.pages.length+1);
      let restChars="";
      const heightLimit=1600;
      for(let i=0;i<this.pages.length;i++){
        if (restChars.length>0){
          this.comments.push(this.pages[i]);
          this.comments[this.comments.length-1].comment="... "+restChars;
          this.comments[this.comments.length-1].created_by=null;
          restChars="";
          i=i+1;
        }
        if(i>=this.pages.length)
          break;
        this.comments.push(this.pages[i]);
        await this.populateHeight();
        if(this.size>heightLimit && (this.size-heightLimit>40)){
          let temp=this.pages[i].comment;
          this.comments[this.comments.length-1].comment="";
          for(let j=0;j<temp.length;j++){
            if(temp[j]=='。' || temp[j]=='.' || temp[j]==','){
              this.comments[this.comments.length-1].comment=temp.substring(0,j+1)+"...";
              await this.populateHeight();
              if(this.size>heightLimit){
                restChars=temp.substring(j+1,temp.length)
                await this.printPDF();
                this.comments=[];
                i=i-1;
                break;
              }
            }
          }
        }
        else if(this.size>heightLimit && (this.size-heightLimit<=40)){
          await this.printPDF();
          this.comments=[];
        }
        this.progressBar.progressValue+=perCommentProcessProgressValue;
      }
      if (restChars.length>0){
        this.comments.push(this.pages[0]);
        this.comments[this.comments.length-1].comment="... "+restChars;
        this.comments[this.comments.length-1].created_by=null;
        restChars="";
      }
      if(this.comments.length>0) {
        this.progressBar.progressValue += perCommentProcessProgressValue;
        await this.printPDF();
      }
      await  this.mergePdfs();
    },
    async mergePdfs() {
      let pdfDoc = await PDFDocument.create();
      let perMergeProcessProgressValue=15/this.pdfBuffer.length;
      for(let i=0;i<=this.pdfBuffer.length;i++){
        if (i==0){
          this.progressBar.statusTxt="Fetching report";
          this.progressBar.bufferValue= this.progressBar.bufferValue+10;
          let DonorPdfBytes = await fetch(this.commentDialog.url)
              .then(res => res.arrayBuffer())
              .finally(()=>{
                this.progressBar.progressValue= this.progressBar.progressValue+10;
                this.progressBar.bufferValue= 100;
              }).catch(err=>{
                this.$store.dispatch('updateDialog', err.response.data.error)
              });
          let DonorPdfDoc = await PDFDocument.load(DonorPdfBytes);
          let [DonorPage] = await pdfDoc.copyPages(DonorPdfDoc, [0]);
          pdfDoc.addPage(DonorPage);
        }else{
          this.progressBar.statusTxt="Merging all rendered data";
          this.progressBar.progressValue += perMergeProcessProgressValue;
          let DonorPdfBytes = this.pdfBuffer[i-1];
          let DonorPdfDoc = await PDFDocument.load(DonorPdfBytes);
          let [DonorPage] = await pdfDoc.copyPages(DonorPdfDoc, [0]);
          pdfDoc.insertPage(i, DonorPage);
        }
      }

      const pdfBytes =await pdfDoc.save();
      let blob = new Blob([pdfBytes], {
        type: 'application/pdf'
      });
      await saveAs(blob,this.commentDialog.filename);
      let url = {
        name: routeNames.USER_DETAILS.name,
        params:{
          associate_data: this.userDetailsData,
          comment_dialog: this.commentDialog
        }
      }
      await this.$router.replace(url);

    },

    async printPDF() {
      this.progressBar.statusTxt="Rendering Processed comments ";
      let svgElements = document.body.querySelectorAll('svg');
      svgElements.forEach(function (item) {
        item.setAttribute("width", item.getBoundingClientRect().width);
        item.setAttribute("height", item.getBoundingClientRect().height);
        item.style.width = null;
        item.style.height = null;
      });


      const el = this.$refs.comment;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL',
        dpi: 150,
        scale: 2,
      }
      this.output = await this.$html2canvas(el, options);
      let doc = new jsPDF("p", "px", "a4");
      let width = doc.internal.pageSize.getWidth();

      let height = doc.internal.pageSize.getHeight();
      doc.addImage(this.output, 'PNG', 0, 0, width, height,
          undefined, 'FAST');
      let file = doc.output('arraybuffer')
      this.pdfBuffer.push(file);
    },
    dateTimeFormater(dateVar){
      let dateTime = new Date(dateVar)
      return dateTime.getFullYear() + "年" +
          (dateTime.getMonth() + 1).toString().padStart(2, '0') + "月" +
          dateTime.getDate().toString().padStart(2, '0') + "日 " +
          dateTime.getHours().toString().padStart(2, '0') + ":" +
          dateTime.getMinutes().toString().padStart(2, '0') + ":" +
          dateTime.getSeconds().toString().padStart(2, '0');
    },
  },
  watch: {
    'barImages': {
      handler: function (val) {
        if (val.headerImage && val.footerImage && val.data) {
          setTimeout(async() => {
            await new Promise((resolve)=>{
              resolve(this.segmentPage());
            });
          }, 500);
        }
      },
      deep: true
    },
  },
  created() {
    this.progressBar.bufferValue=5;
    this.progressBar.statusTxt="fetching data ";
    if(this.$route.params.data){
      this.commentDialog=this.$route.params.data;
      this.pages=this.commentDialog.comments;
      this.barImages.data=true;
    }
    if(this.$route.params.user_details_data){
      this.userDetailsData=this.$route.params.user_details_data;
    }
    this.progressBar.progressValue=5;
  },
  beforeRouteEnter(to, from, next) {
    if (!store.getters.loggedIn) {
      next({
        name: routeNames.LOGIN,
      })
    } else {
      if (to.params.data) {
        next()
      } else {
        next({
          name: routeNames.USER_MANAGEMENT.name,
        })
      }
    }
  }

}
</script>

<style scoped>
.pre-formatted {
  white-space: pre-line;
}
</style>