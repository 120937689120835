import routeNames from "@/router/routes/routeNames";
import ManageUser from "@/views/ManageUser";
import UserList from "@/views/UserList";
import UserDetails from "@/views/UserDetails";
import CommentPdfDownload from "@/views/CommentPdfDownload";
import CommentPdfZipDownload from "@/views/CommentPdfZipDownload";

const routes = [
    {
        path: routeNames.USER_MANAGEMENT.path,
        name: routeNames.USER_MANAGEMENT.name,
        component: ManageUser
    },
    {
        path: routeNames.USER_LIST.path,
        name: routeNames.USER_LIST.name,
        component: UserList
    },
    {
        path: routeNames.USER_DETAILS.path,
        name: routeNames.USER_DETAILS.name,
        component: UserDetails
    },
    {
        path: routeNames.COMMENT_PDF.path,
        name: routeNames.COMMENT_PDF.name,
        component: CommentPdfDownload
    },
    {
        path: routeNames.COMMENT_PDF_ZIP.path,
        name: routeNames.COMMENT_PDF_ZIP.name,
        component: CommentPdfZipDownload
    },
]

export default routes.map(route => {
    return {
        ...route,
        meta: {
            public: false,
            title: route.name
        }
    }
})