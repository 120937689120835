<template>
  <v-app-bar
      app
      color="white"
      light
      class="px-4"
  >
    <LogoutDialog :visible="logoutDialog.visible" @close="logoutDialog.visible=false"/>
    <v-container>
      <a class="d-flex align-center" @click="managePage">
        <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            :src="require('@/assets/skillsscan-logo.svg')"
            transition="scale-transition"
            width="150"
        />
      </a>
    </v-container>
    <v-spacer/>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="grey"
            dark
            v-bind="attrs"
            v-on="on"
            plain
        >
          <span class="black--text">{{ username }}</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
            v-for="(item, index) in items"
            :key="index"
        >

          <v-list-item-title @click="menuClick(index)">
            <a style="color: black"> {{ item.title }}</a>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import routeNames from "@/router/routes/routeNames";
import LogoutDialog from "@/components/Dialogs/LogoutDialog";
import axios from "axios";
import api from "@/helper/api";

export default {
  name: "NavBar",
  components: {LogoutDialog},
  data() {
    return {
      username: null,
      homePath: routeNames.USER_MANAGEMENT,
      items: [
        {title: 'ログアウト'},
      ],
      logoutDialog: {
        visible: false
      }
    }
  },
  methods: {
    managePage() {
      if (this.$route.name !== routeNames.USER_MANAGEMENT.name) {
        this.$router.push(this.homePath)
      }
    },
    logout() {
      this.logoutDialog.visible = true
    },
    menuClick(index) {
      if (index === 0) {
        this.logout()
      }
    },
    loadProfileDetails() {
      axios.get(api.GET_PROFILE_INFO)
          .then((res) => {
            this.username = res.data.user_name
          }).catch(err => {
        this.$store.dispatch('updateDialog', err.response.data.error)
      })
    }
  },
  mounted() {
    this.loadProfileDetails()
  },
  watch: {
    '$route': function (val) {
      if (val.name === routeNames.USER_MANAGEMENT.name) {
        this.loadProfileDetails()
      }
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .container {
    max-width: 1064px !important;
  }
}
</style>
