import AWS from 'aws-sdk';

export default class S3Client {

    constructor(s3Key) {
        this.Params.Key = s3Key
        this.DeleteParams.Key = s3Key
        this.DownloadParams.Key=s3Key
    }

    s3Config = new AWS.S3({
        accessKeyId: process.env.VUE_APP_S3_ACCESS_KEY_ID,
        secretAccessKey: process.env.VUE_APP_S3_SECRET_ACCESS_KEY,
    });

    Params = {
        Bucket: process.env.VUE_APP_S3_BUCKET,
        Key: null,
        ResponseContentType: "application/pdf",
        Expires: 60 * 5 // 5 mins
    };
    DownloadParams = {
        Bucket: process.env.VUE_APP_S3_BUCKET,
        Key: null,
        Body:null,
    };
    DeleteParams = {
        Bucket: process.env.VUE_APP_S3_BUCKET,
        Key: null,
    }

    deleteFile() {
        return new Promise(((resolve, reject) => {
            this.s3Config.deleteObject(this.DeleteParams, function (err, data) {
                if (err) {
                    reject(err)
                } else {
                    resolve(data)
                }
            });
        }))
    }

    getUrl() {
        return this.s3Config.getSignedUrl('getObject', this.Params)
    }
    getDownloadUrl() {
        return this.s3Config.getSignedUrl('getObject', this.DownloadParams)
    }
}
