import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import router from './router'
import store from './store'
import routeNames from "@/router/routes/routeNames";
import axios from 'axios';
import VueLoading from 'vuejs-loading-plugin'
import responseStatus from "@/helper/responseStatus";
import VueHtml2Canvas from 'vue-html2canvas';
axios.defaults.baseURL = process.env.VUE_APP_API_BASE;
axios.defaults.headers.common['Language'] = 'jp';
axios.interceptors.request.use((config) => {
    if (store.getters.loggedIn) {
        config.headers.common['Authorization'] = store.getters.getToken;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
axios.interceptors.response.use((response) => {
        return response
    }, (error => {
        const originalRequest = error.config;
        if (error.response.status === responseStatus.UNAUTHORIZED) {
            store.dispatch('logout').then(() => {
                router.push(routeNames.LOGIN)
            })
        } else if (error.response.status === responseStatus.METHOD_NOT_ALLOWED) {
            originalRequest._retry = true;
            return store.dispatch('refreshToken').then((token) => {
                originalRequest.headers.Authorization = token;
                return axios(originalRequest);
            }).catch(() => {
                store.dispatch('logout').then(() => {
                    router.push(routeNames.LOGIN)
                })
            })
        }
        return Promise.reject(error);
    })
)

Vue.config.productionTip = false
Vue.use(VueLoading, store.getters.getLdOptions)
Vue.use(VueHtml2Canvas);
new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')