import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from '@/router/routes/index.js'
import store from '@/store'
import routeNames from "@/router/routes/routeNames";

Vue.use(VueRouter)

const DEFAULT_TITLE = 'Skills-Scan Admin';
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
        window.scrollTo(0, 0);
    });

    const loggedIn = store.getters.loggedIn
    const isPublic = to.matched.some(record => record.meta.public)
    if (!isPublic && !loggedIn) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        return next({
            name: routeNames.LOGIN.name,
        })
    }
    next()
})

export default router
