<template>
  <div>
    <v-container fluid fill-height>
      <v-row align="center" justify="center" style="min-height: 600px" class="ma-0 pa-0">
        <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            :src="require('@/assets/skillsscan-logo.svg')"
            transition="scale-transition"
            style="margin-bottom: -80px"
        />
        <v-col cols="12">
          <v-card
              class="mx-auto"
              max-width="500"
          >
            <v-card-title>
              <span class="ms-2 title font-weight-bold">ログイン</span>
            </v-card-title>
            <v-text-field
                v-model="username"
                label="ユーザー名"
                outlined
                class="mx-5 mt-5"
            ></v-text-field>

            <v-text-field
                v-model="password"
                label="パスワード"
                outlined
                class="mx-5"
                :rules="passwordRules"
                :append-icon="txtPassShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="txtPassShow ? 'text' : 'password'"
                @click:append="txtPassShow = !txtPassShow"
            ></v-text-field>

            <v-card-actions>
              <v-list-item class="grow">
                <v-row
                    align="center"
                    justify="center"
                >
                  <v-col class="text-end">
                    <v-btn
                        color="orange"
                        class="px-5"
                        :disabled="!isValid"
                        @click="login"
                    >
                      <div class="white--text">ログイン</div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import routeNames from "@/router/routes/routeNames";
import store from "@/store";
import {asyncLoading} from "vuejs-loading-plugin";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      password: null,
      username: null,
      txtPassShow: false,
      showForgotPassDialog: false,
      passwordRules: [
        v => !!v || '必須',
        v => /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&()|/_-])[A-Za-z\d@$!%*#?&()_-|/]{8,}$/.test(v)
            || "パスワードが無効です。パスワードはアルファベット(最低1つの大文字を含む）と数字、特殊文字を含む8文字以上の半角文字で設定してください。",
      ],
    }
  },
  methods: {
    login() {
      let credential = {
        username: this.username,
        password: this.password
      }
      asyncLoading(this.$store.dispatch('login', credential)).then(() => {
        this.$store.dispatch("normalLd");
        this.$router.push(routeNames.USER_MANAGEMENT);
      }).catch((error) => {
        this.$store.dispatch('updateDialog', error)
      })
    }
  },
  computed: {
    isValid() {
      let matches = /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&()|/_])[A-Za-z\d@$!%*#?&()_|/]{8,}$/.test(this.password);
      return (this.password && matches) && this.username
    }
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters.loggedIn) {
      store.dispatch('createSnack', "既にログイン済みです。最初にログアウトする必要があります。")
      next({
        name: routeNames.USER_MANAGEMENT.name,
      })
    } else {
      next()
    }
  }
}
</script>

<style scoped>

</style>
