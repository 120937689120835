<template>
  <v-dialog
      v-model="show"
      width="500"
  >
    <v-card>
      <v-card-title
          class="headline mb-4"
          primary-title
      >
        <v-img
            alt="Logo"
            class="shrink"
            contain
            width="130"
            :src="require('@/assets/skillsscan-logo.svg')"
        />
      </v-card-title>
      <v-card-text>
        ログアウトしてもよろしいですか。
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="yellow darken-4"
            text
            @click="show = false"
        >
          いいえ
        </v-btn>
        <v-btn
            color="yellow darken-4"
            text
            @click="logout"
        >
          はい
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import routeNames from "@/router/routes/routeNames";
import { asyncLoading } from 'vuejs-loading-plugin'
export default {
  name: "LogoutDialog",
  props: {
    visible: Boolean,
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    logout() {
      this.show = false
      asyncLoading(this.$store.dispatch('logout')).then(() => {
        this.$router.push(routeNames.LOGIN)
      }).catch((error) => {
        this.$store.dispatch('updateDialog', error)
      })
    }
  }
}
</script>

<style scoped>

</style>