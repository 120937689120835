const state = {
    routeParams: null,
}

const getters = {
    getRouteParams(state) {
        return state.routeParams
    },
}

const mutations = {
    setRouteParams(state, params) {
        state.routeParams = params
    },
}

const actions = {
    updateRouteParams(context, params) {
        context.commit('setRouteParams', params)
    },
    clearRouteParams(context) {
        context.commit('setRouteParams', null)
    },

}

export default {
    state,
    getters,
    mutations,
    actions
}