export default {
    LOGIN: 'admin_account_login/',
    LOGOUT: 'admin_account_logout/',
    REFRESH: 'token/refresh/',
    GET_USER_LIST: 'user/list/?page=',
    DOWNLOAD_PDF: 'download/report/',
    SEARCH_USER_EMAIL_ID: 'user/search/',
    GET_USER_DETAILS: 'user/details/',
    GET_PROFILE_INFO:'admin/profile/info',
    GET_ADMIN_COMMENT_LIST:'comment/list',
    CREATE_ADMIN_COMMENT:'comment/create',
    UPDATE_ADMIN_COMMENT: 'comment/update',
    DELETE_ADMIN_COMMENT: 'comment/delete',
    ADMIN_DOWNLOAD_COMMENT_PDF_USERSET:'comment/download/pdf/userset',
    ADMIN_DOWNLOAD_COMMENT_PDF_MAPSET:'comment/download/pdf/mapset',
}