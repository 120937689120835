<template>
  <v-btn
      class="px-13"
      large
      rounded
      :outlined="outlined"
      :max-width="maxWidth"
      v-bind:dark="!disabled"
      :disabled="disabled"
      @click="clickAction"
      :small="small"
      :loading="isLoading"
      :color="btnColor">
    <v-icon left v-if="!!btnIcon">{{ btnIcon }}</v-icon>
    <div class="subtitle-1 font-weight-bold">{{ btnText }}</div>
  </v-btn>
</template>

<script>
export default {
  name: "BaButton",
  props: {
    btnText: String,
    disabled: Boolean,
    color: String,
    maxWidth: Number,
    txtColor: String,
    outlined: Boolean,
    btnIcon: String,
    small: Boolean,
    loading:Boolean,
  },
  methods: {
    clickAction() {
      this.$emit('click')
    },
  },
  computed: {
    btnColor() {
      if (!this.color) {
        return "light-blue darken-2"
      } else {
        return this.color
      }
    },
    colorTxt() {
      if (!this.txtColor) {
        return "white--text"
      } else {
        return this.txtColor + "--text"
      }
    },
    isLoading(){
      if(this.loading){
        return this.loading;
      }else{
        return false;
      }
    }
  }
}
</script>

<style scoped>

</style>