<template>
  <div class="flex">
    <div class="px-4 py-10">

      <v-container class="white">
        <v-row>
          <v-col>
            <v-row no-gutters>
              <v-col>
              <v-card-text  class="l-subtitle text-start text-h4 mb-8 font-weight-bold">登録者リスト</v-card-text>
              </v-col>
            </v-row>
            <v-card class="ml-3 ml-sm-3 ml-md-0 mr-3 mr-sm-3 mr-md-0 white py-10 px-10 ss-check-result box-card" flat>
              <v-row no-gutters>
                <v-col>
                  <h3 class="text-start text-h5 font-weight-bold mb-5">全登録者</h3>
                  <BaButton class="mr-5 float-left" btn-text="リストを表示" :outlined="true" @click="goToListPage"
                            color="light-blue darken-2"/>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-10">
                <v-col cols="12">
                  <h3 class="text-start text-h5 font-weight-bold mb-8">検索</h3>
                </v-col>
                <v-col cols="9">
                  <v-text-field
                      label="メールアドレスもしくはユーザID"
                      outlined
                      dense
                      color="yellow darken-4"
                      v-model="userId"
                      v-on:input="checker"
                      required
                  >
                    <template v-slot:append v-if="!this.searchButton.disable">
                      <v-icon color="green">mdi-check-circle</v-icon>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="3">
                  <BaButton btn-text="登録者検索" :outlined="this.searchButton.outline" :disabled="this.searchButton.disable"
                            @click="gotoDetails"/>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-container class="white py-10">
        <v-row>
          <v-col>
            <v-card class="ml-3 ml-sm-3 ml-md-0 mr-3 mr-sm-3 mr-md-0 white pt-10 px-10 pb-16 ss-check-result box-card"
                    flat>
              <h3 class="text-start mb-5">全登録者の最新マップを一括ダウンロードする</h3>
              <BaButton class="float-left" btn-text="ダウンロード" :outlined="false" @click="downloadAll"
                        btnIcon="mdi-download-outline"/>
              <BaButton class="float-left ml-5" btn-text="コメント付きでダウンロード" :outlined="false" @click="downloadAllwithComment"
                        btnIcon="mdi-download-outline"/>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import BaButton from "@/components/BaButton";
import store from "@/store";
import routeNames from "@/router/routes/routeNames";
import S3Client from "@/helper/S3Client";
import axios from "axios";
import api from "@/helper/api";
// import { saveAs } from 'file-saver'

export default {
  name: "ManageUser",
  components: {BaButton},
  data() {
    return {
      test: true,
      userId: null,
      countdown: null,
      searchButton: {
        outline: false,
        disable: true
      },
      accountID: null,
      prevRoute:null,
    }
  },
  methods: {
    goToListPage() {
      this.$router.push({path: routeNames.USER_LIST.path, query: {page: '1'}})
    },
    downloadAll() {
      this.$loading(true)
      axios.post(
          api.DOWNLOAD_PDF,
          JSON.stringify({
            account_id: [],
            keys: []
          })).then((res) => {
        window.location.assign(res.data.public_url)
        let s3Client = new S3Client(res.data.key)
        setTimeout(function () {
          s3Client.deleteFile()
        }, 3000)
      }).catch(err => {
        this.$store.dispatch('updateDialog', err.response.data.error)
      }).finally(() => {
        this.$loading(false)
      })
    },
    checker() {
      if (this.userId) {
        this.searchButton.outline = true
      } else {
        this.searchButton.outline = false
        this.searchButton.disable = true
      }
    },
    searchUserID() {
      if (this.userId && this.userId.length > 0) {
        axios.post(api.SEARCH_USER_EMAIL_ID, {data: this.userId})
            .then((response) => {
              if (response.data.user_exist) {
                this.accountID = response.data.account_id
                this.searchButton.disable = false
              } else {
                this.accountID = null
                this.searchButton.disable = true
              }
            })
            .catch((error) => {
              this.$store.commit('setDialog', error)
            })
      }
    },
    gotoDetails() {
      let name = routeNames.USER_DETAILS.name
      let url = {
        name: name,
        params: {
          account_id: this.accountID
        },
      }
      this.$router.push(url)

    },
    downloadAllwithComment(){
      axios.post(api.ADMIN_DOWNLOAD_COMMENT_PDF_USERSET,{account_id:[]})
          .then((response) => {
            let url = {
              name: routeNames.COMMENT_PDF_ZIP.name,
              params: {
                data: response.data
              },
            }
            this.$router.replace(url);
          });
    },
  },
  watch: {
    userId(val) {
      if (val) {
        if (this.countdown) {
          clearTimeout(this.countdown)
        }
        this.countdown = setTimeout(() => this.searchUserID(), 750)
      }
    },
  },
  created() {
    this.$store.dispatch('normalLd');
    this.$loading(false);
  },
  beforeRouteEnter(to, from, next) {
    if (!store.getters.loggedIn) {
      next({
        name: routeNames.LOGIN.name,
      })
    } else {
      next()
    }
  }
}
</script>

<style scoped>
@media only screen and (min-width: 960px) {
  .container {
    max-width: 1064px !important;
  }
}

.ss-check-result {
  border: 1px solid #cccccc !important;
  padding-left: 10px;
  padding-right: 10px;
}

.l-subtitle {
  font-size: 14px;
  padding: 4px 16px 0px 16px;
  border-left: 4px solid #FBC02D;
}
</style>
