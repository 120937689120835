<template>
  <v-container fluid ref="comment" class="ma-auto pa-0 shrink" style="width: 1280px; height: 1800px;" >
    <!--    Header Start-->
    <v-sheet
        class="pa-0 mx-0 flex-column d-flex justify-center"
        color="#F5F5F5"
        height="128px"
        width="100%"
    >
      <v-container
          class="px-0 px-sm-0 px-md-0"
      >
        <v-row
            no-gutters
            class="mx-15"
        >
          <v-col cols="auto">
            <img
                alt="skills-scan_logo"
                width="250"
                height="44"
                src="../assets/skillsscan-logo.svg"
                @load="barImages.headerImage=true"
            />
          </v-col>
          <v-col cols="auto" class="px-6 mr-6 font-weight-bold flex-column d-flex justify-center">
            コメント
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="font-weight-medium flex-column d-flex justify-center subtitle-1 font-weight-black">
            <v-row no-gutters
                   class="pa-0 ma-0 align-center "
            >
              回答者 : {{ getName }}
            </v-row>
            <v-row no-gutters
                   class="pa-0 ma-0 align-start"
            >
              回答日時 : {{ currentDateTime }}
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    <!--    Header End-->
    <div class="pa-0 ma-0" ref="commentList">
      <!--    Comment Start -->
      <v-row  class="mx-15" v-for="comment in comments" :key="comment.comment_id+comment.created_by" no-gutters justify="center">
        <v-card
            width="90%"
            flat
        >
          <v-card-subtitle v-if="comment.created_by!=null" class="text-start font-weight-bold text-sm-h5">{{ comment.created_by }} :</v-card-subtitle>
          <v-card-subtitle v-if="comment.created_by!=null && comment.edited_by==null" class="mt-n5 text-end font-weight-medium">{{ dateTimeFormater(comment.created_at) }}</v-card-subtitle>
          <v-card-subtitle  v-if="comment.created_by!=null && comment.edited_by!=null" class="mt-n5 text-end font-weight-medium">{{comment.edited_by }} に {{ dateTimeFormater(comment.edited_at)}} に編集されました  {{ dateTimeFormater(comment.created_at) }}</v-card-subtitle>
          <v-card-text class="pre-formatted text-start font-weight-medium">{{  comment.comment }}</v-card-text>
        </v-card>
      </v-row>
    </div>
    <!--    Comment End-->
    <!--    Footer text Start-->
    <v-footer
        absolute
        padless
        class="mt-10"
        color="#F5F5F5"
    >
      <v-container style="width: 1247px;">

        <v-row class="mx-15 pb-3 pt-3">
          <v-col cols="auto" class="text-left">
            <img
                alt="ba_logo"
                :src="require('@/assets/BA-Logo_BW.png')"
                height="44"
                width="58"
                @load="barImages.footerImage=true"
            />
          </v-col>
          <v-col md="col-auto" class="text-left text-sm-left text-md-right pt-6 black--text">
            © {{ new Date().getFullYear() }} <span>Business Architects Inc</span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <!--    Footer End-->

  </v-container>
</template>
<script>
import {jsPDF} from "jspdf";
import { PDFDocument } from 'pdf-lib';
import S3Client from "@/helper/S3Client";
import JSZip from 'jszip';
import routeNames from "@/router/routes/routeNames";
import store from "@/store";
import { saveAs } from 'file-saver'
export default {
  name: "CommentPdfZipDownload",
  data() {
    return {
      barImages: {
        headerImage: false,
        footerImage: false,
        data: false,
      },
      comments:[],
      pages:[],
      size:0,
      pdfBuffer:[],
      data:null,
      url:"",
      filename:"",
      name:"",
      zip:null,
      type:"",
      fromRoute:null,
      userListData: {},
      userDetailsData:{}
    }
  },
  inject:["progressBar"],
  computed:{
    currentDateTime() {
      let dateTime = new Date()
      return dateTime.getFullYear() + "/" +
          (dateTime.getMonth() + 1).toString().padStart(2, '0') + "/" +
          dateTime.getDate().toString().padStart(2, '0') + " " +
          dateTime.getHours().toString().padStart(2, '0') + ":" +
          dateTime.getMinutes().toString().padStart(2, '0');
    },
    getName() {
      return "Admin"
    },
  },
  updated() {
    this.size=this.$refs.commentList.clientHeight;
  },
  methods:{
    async populateHeight(){
      this.size=this.$refs.commentList.clientHeight;
    },
    async segmentPage(){
      let restChars="";
      const heightLimit=1550;
      for(let i=0;i<this.pages.length;i++){
        if (restChars.length>0){
          this.comments.push(this.pages[i]);
          this.comments[this.comments.length-1].comment="... "+restChars;
          this.comments[this.comments.length-1].created_by=null;
          restChars="";
          i=i+1;
        }
        if(i>=this.pages.length)
          break;
        this.comments.push(this.pages[i]);
        await this.populateHeight();
        if(this.size>heightLimit && (this.size-heightLimit>30)){
          let temp=this.pages[i].comment;
          this.comments[this.comments.length-1].comment="";
          for(let j=0;j<temp.length;j++){
            if(temp[j]=='。'|| temp[j]=='.'  || temp[j]==','){
              this.comments[this.comments.length-1].comment=temp.substring(0,j+1)+"...";
              await this.populateHeight();
              if(this.size>heightLimit){
                restChars=temp.substring(j+1,temp.length)
                await this.printPDF();
                this.comments=[];
                i=i-1;
                break;
              }
            }
          }
        }
        else if(this.size>heightLimit && (this.size-heightLimit<=30)){
          await this.printPDF();
          this.comments=[];
        }
      }
      if (restChars.length>0){
        this.comments.push(this.pages[0]);
        this.comments[this.comments.length-1].comment="... "+restChars;
        this.comments[this.comments.length-1].created_by=null;
        restChars="";
      }
      if(this.comments.length>0)
        await this.printPDF();
      await  this.mergePdfs();
    },
    async mergePdfs() {
      let pdfDoc = await PDFDocument.create();
      for(let i=0;i<=this.pdfBuffer.length;i++){
        if (i==0){
          let DonorPdfBytes = await fetch(this.url).then(res => res.arrayBuffer()).catch(err=>{
            this.$store.dispatch('updateDialog', err.response.data.error)
          });
          let DonorPdfDoc = await PDFDocument.load(DonorPdfBytes);
          let [DonorPage] = await pdfDoc.copyPages(DonorPdfDoc, [0]);
          pdfDoc.addPage(DonorPage);
        }else{
          let DonorPdfBytes = this.pdfBuffer[i-1];
          let DonorPdfDoc = await PDFDocument.load(DonorPdfBytes);
          let [DonorPage] = await pdfDoc.copyPages(DonorPdfDoc, [0]);
          pdfDoc.insertPage(i, DonorPage);
        }
      }

      const pdfBytes = await pdfDoc.save();
      let blob = new Blob([pdfBytes], {
        type: 'application/pdf'
      });
      this.zip.file(this.name+"/"+this.type+"/"+this.filename, blob);
    },
    async printPDF() {
      let svgElements = document.body.querySelectorAll('svg');
      svgElements.forEach(function (item) {
        item.setAttribute("width", item.getBoundingClientRect().width);
        item.setAttribute("height", item.getBoundingClientRect().height);
        item.style.width = null;
        item.style.height = null;
      });


      const el = this.$refs.comment;
      // add option type to get the image version
      // if not provided the promise will return
      // the canvas.
      const options = {
        type: 'dataURL',
        dpi: 150,
        scale: 2,
      }
      this.output = await this.$html2canvas(el, options);
      let doc = new jsPDF("p", "px", "a4");
      let width = doc.internal.pageSize.getWidth();

      let height = doc.internal.pageSize.getHeight();
      doc.addImage(this.output, 'PNG', 0, 0, width, height,
          undefined, 'FAST');
      let file = doc.output('arraybuffer')
      this.pdfBuffer.push(file);
    },
    dateTimeFormater(dateVar){
      let dateTime = new Date(dateVar)
      return dateTime.getFullYear() + "年" +
          (dateTime.getMonth() + 1).toString().padStart(2, '0') + "月" +
          dateTime.getDate().toString().padStart(2, '0') + "日 " +
          dateTime.getHours().toString().padStart(2, '0') + ":" +
          dateTime.getMinutes().toString().padStart(2, '0') + ":" +
          dateTime.getSeconds().toString().padStart(2, '0');
    },
    async processData() {
      this.progressBar.statusTxt="collecting user data ";
      let userAccounts = this.data.user_account_list;
      let perAccountProgressValue=90/userAccounts.length;
      for (const account of userAccounts) {
        this.progressBar.bufferValue+=perAccountProgressValue;
        this.name=account.name;
        this.progressBar.statusTxt="processing data of "+this.name;
        let perAccountperReportProgressValue=perAccountProgressValue/account.user_map_list.length;
        let i=0;
        for (const map of account.user_map_list) {
          i=i+1;
          this.progressBar.statusTxt="processing data of "+this.name+" ("+i+" out of "+account.user_map_list.length+")";
          this.pages = [];
          this.comments = [];
          this.pdfBuffer=[];
          this.pages = map.comment_list;
          let s3Client = new S3Client(map.map_key);
          this.url = s3Client.getDownloadUrl();
          this.filename=this.name+"_"+map.map_id+".pdf";
          if(map.map_type==1){
            this.type="skillmap";
          }else if(map.map_type==2){
            this.type="experiencemap";
          }
          await this.segmentPage();
          this.progressBar.progressValue+=perAccountperReportProgressValue;
        }
        this.progressBar.progressValue= this.progressBar.bufferValue;
      }
      this.progressBar.statusTxt="Zipping data ";
      this.progressBar.bufferValue=100;
      this.zip.generateAsync({type:"blob"})
          .then(async (content)=>{
            saveAs(content,this.data.admin+".zip");
            this.progressBar.progressValue=100;
            this.gotoFromPage();
          }, function(){
            setTimeout(() => { alert("Something went wrong.Please try again.");close();}, 500);
          });
    },
    gotoFromPage(){
      let url= null;
      if(this.fromRoute.name==routeNames.USER_DETAILS.name) {

        url = {
          name: routeNames.USER_DETAILS.name,
          params: {
            associate_data:this.userDetailsData,
          }
        }
      }
      else if(this.fromRoute.name==routeNames.USER_LIST.name) {
        url = {
          name: routeNames.USER_LIST.name,
          params:{
            associate_data:this.userListData,
          },
          query: {page: this.userListData.page}
        }
      }else{
        url = {
          name: routeNames.USER_MANAGEMENT.name,
        }
      }
      this.$router.replace(url);
    }
  },
  watch: {
    'barImages': {
      handler: function (val) {
        if (val.headerImage && val.footerImage && val.data) {
          setTimeout(async () =>{
            await new Promise((resolve)=>{
              resolve(this.processData());
            });
          }, 500);
        }
      },
      deep: true
    },
  },
  created() {
    this.progressBar.bufferValue=5;
    this.progressBar.statusTxt="fetching data ";
    if (this.$route.params.user_details_data) {
      this.userDetailsData=this.$route.params.user_details_data;
    }
    if(this.$route.params.user_list_data){
      this.userListData=this.$route.params.user_list_data;
    }
    if(this.$route.params.data){
      this.data=this.$route.params.data;
    }
    this.zip = new JSZip();
    this.barImages.data=true;
    this.progressBar.progressValue=5;
  },
  beforeRouteEnter(to, from, next) {
    if (!store.getters.loggedIn) {
      next({
        name: routeNames.LOGIN,
      })
    } else {
      if (to.params.data) {
        next(vm=>{
          vm.fromRoute=from;
        })
      } else {
        next({
          name: routeNames.USER_MANAGEMENT.name,
        })
      }
    }
  }
}
</script>

<style scoped>
.pre-formatted {
  white-space: pre-line;
}
</style>