<template>
  <v-app id="app">
    <Progressbar :progressBar="progressBar" v-if="$route.path=='/comment/pdf' ||  $route.path=='/comment/pdf/zip'"/>
    <v-main>
      <NavBar v-if="$store.getters.loggedIn && $route.path!='/comment/pdf' && $route.path!='/comment/pdf/zip' "/>
      <GlobalDialog/>
      <GlobalSnackBar/>
      <div v-if="$store.getters.loggedIn && $route.path!='/comment/pdf' && $route.path!='/comment/pdf/zip'" class="mt-12">
        <div class="pt-8 text-h3 mb-5 font-weight-bold" v-if="companyName.length > 0">{{ companyName }}</div>
        <div class="pb-8 text-h3 font-weight-bold">Skills Scan 登録ユーザ管理ページ</div>
      </div>
      <router-view/>
    </v-main>
    <Footer v-if="$store.getters.loggedIn && $route.path!='/comment/pdf' && $route.path!='/comment/pdf/zip'"/>
  </v-app>
</template>

<script>
import NavBar from "@/components/NavBar";
import Footer from "@/components/Footer";
import GlobalDialog from "@/components/Dialogs/GlobalDialog";
import GlobalSnackBar from "@/components/GlobalSnackBar";
import Progressbar from "@/components/Loaders/Progressbar";

export default {
  components: {Progressbar, GlobalSnackBar, GlobalDialog, NavBar, Footer},
  data() {
    return {
      progressBar: {
        bufferValue: 0,
        progressValue: 0,
        statusTxt: "",
      }
    }
  },
  computed: {
    companyName() {
      if (this.$store.getters.getCompanyName) {
        return this.$store.getters.getCompanyName.replaceAll("#", "");
      } else {
        return ""
      }

    }
  },
  provide(){
    return {
      progressBar: this.progressBar
    }
  },
  created() {
    this.$store.dispatch('normalLd')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>