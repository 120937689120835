<template>
  <div>
    <h1>(404) Page Not Found</h1>
  </div>
</template>

<script>
export default {
  name: "Error"
}
</script>

<style scoped>

</style>