<template>
  <v-dialog
      fullscreen
      style="overflow: hidden;"
      transition="dialog-top-transition"
      v-model="commentDialog.show"
  >
      <v-card :height="getScreenHeight" style="overflow: hidden;" class="pb-5" >
        <v-toolbar
            dark
            color="primary"
            style="z-index: +9"
            ref="toolbar"
        >
          <v-card-text class="text-h6 font-weight-bold text-start">コメント</v-card-text>
          <v-spacer></v-spacer>
          <v-btn
              class="pa-2"
              icon
              dark
              @click="hideComment"
          >

            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row style="height: 90%">
<!--        PDF preview part start    -->
          <v-col style="height: 100%" class="ml-3">
            <v-card flat  style="overflow: hidden;height: inherit" class="mt-2">
              <v-speed-dial
                  v-model="fab"
                  class="mb-15"
                  bottom
                  left
                  absolute
                  :direction="direction"
                  open-on-hover
                  :transition="transition"
              >
                <template v-slot:activator>
                  <v-btn
                      v-model="fab"
                      color="#509E02"
                      fab
                      dark
                      small
                  >
                    <v-icon v-if="fab">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-download-outline
                    </v-icon>
                  </v-btn>
                </template>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        fab
                        dark
                        small
                        color="indigo"
                        @click="downloadReportPDFwithoutComment"
                    >
                      <v-icon>mdi-download-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>PDFでマップのみダウンロード</span>
                </v-tooltip>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :disabled="isDownloadWithCommentEnable"
                        v-bind="attrs"
                        v-on="on"
                        v-model="fab"
                        color="#f57f17"
                        fab
                        dark
                        small
                        @click="downloadReportPDFwithComment"
                    >
                      <v-icon>mdi-download-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>コメント付きでダウンロード</span>
                </v-tooltip>
              </v-speed-dial>
              <object height="100%" width="100%" :data="commentDialog.url" type="application/pdf">
                <embed :src="commentDialog.url" type="application/pdf" />
              </object>
            </v-card>
          </v-col>
<!--        PDF preview part end      -->
<!--          comment part start      -->
<!--          add comment start       -->
          <v-col style="height: 100%" class="mb-10">
            <div ref="add_comment">
            <v-row v-if="this.commentDialog.is_addable" justify="center" class="pt-5">
              <v-col cols="11">
                <v-textarea
                    :disabled="this.commentLoading"
                    filled
                    outlined
                    :rules="commentRules"
                    v-model="commentText"
                    label="コメントをご記入ください！"
                ></v-textarea>
               <p class="mt-n7 grey--text" align="right"> {{ commentText.length}}/4000</p>
              </v-col>
            </v-row>
            <v-row  v-if="this.commentDialog.is_addable" no-gutters justify="center" class="pb-5 mt-n6">
                  <v-col cols="11">
                    <BaButton
                        :disabled="!isCommentEnable"
                        :loading="commentLoading"
                        :btn-text="commentBtnTxt"
                        :color="commentBtnColor"
                        @click="addComment"
                        :outlined="false"
                    ></BaButton>
                  </v-col>

                </v-row>
            <hr v-if="this.commentDialog.is_addable" />
            </div>
<!--          add comment end         -->
            <v-card
                flat
                width="100%"
                class="mt-2 wrap"
                :height="commentHeight"
                style="overflow: auto;"
            >
<!--          skeleton loader starts    -->
              <div v-if="commentDialog.comments==null">
              <v-row no-gutters justify="center" class="pt-0 mb-4" >
                <v-card
                    width="95%"
                    flat
                    class="grey lighten-4 rounded-card"
                >
                  <v-card-subtitle class="text-start font-weight-bold"><v-skeleton-loader type="heading"></v-skeleton-loader></v-card-subtitle>
                  <v-card-text class="pre-formatted text-start font-weight-medium"><v-skeleton-loader type="paragraph"></v-skeleton-loader></v-card-text>
                  <v-card-actions>
                    <v-btn
                        rounded
                        text
                        class="text-caption"
                    >
                      <v-skeleton-loader type="button"></v-skeleton-loader>
                    </v-btn>
                    <v-btn
                        rounded
                        text
                        class="text-caption"
                    >
                      <v-skeleton-loader type="button"></v-skeleton-loader>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-row>

                <v-row no-gutters justify="center" class="pt-0 mb-4" >
                  <v-card
                      width="95%"
                      flat
                      class="grey lighten-4 rounded-card"
                  >
                    <v-card-subtitle class="text-start font-weight-bold"><v-skeleton-loader type="heading"></v-skeleton-loader></v-card-subtitle>
                    <v-card-text class="pre-formatted text-start font-weight-medium"><v-skeleton-loader type="paragraph"></v-skeleton-loader></v-card-text>
                    <v-card-actions>
                      <v-btn
                          rounded
                          text
                          class="text-caption"
                      >
                        <v-skeleton-loader type="button"></v-skeleton-loader>
                      </v-btn>
                      <v-btn
                          rounded
                          text
                          class="text-caption"
                      >
                        <v-skeleton-loader type="button"></v-skeleton-loader>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-row>

                <v-row no-gutters justify="center" class="pt-0 mb-4" >
                  <v-card
                      width="95%"
                      flat
                      class="grey lighten-4 rounded-card"
                  >
                    <v-card-subtitle class="text-start font-weight-bold"><v-skeleton-loader type="heading"></v-skeleton-loader></v-card-subtitle>
                    <v-card-text class="pre-formatted text-start font-weight-medium"><v-skeleton-loader type="paragraph"></v-skeleton-loader></v-card-text>
                    <v-card-actions>
                      <v-btn
                          rounded
                          text
                          class="text-caption"
                      >
                        <v-skeleton-loader type="button"></v-skeleton-loader>
                      </v-btn>
                      <v-btn
                          rounded
                          text
                          class="text-caption"
                      >
                        <v-skeleton-loader type="button"></v-skeleton-loader>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-row>
              </div>
<!--          skeleton loader ends    -->
<!--          real comments start here    -->

              <div v-else>

              <v-row class="pt-2" v-for="(comment, index)  in commentDialog.comments" :key="comment.comment_id" no-gutters justify="center">
                <v-card
                    width="95%"
                    flat
                    class="grey lighten-4 rounded-card"
                    v-if="index!=editableIndex"
                >
                  <v-card-subtitle class="text-start font-weight-bold">{{ comment.created_by }}</v-card-subtitle>
                  <v-card-text class="pre-formatted text-start font-weight-medium">{{  comment.comment }}</v-card-text>
                  <v-card-actions>
                    <v-btn
                        v-if="isCommentEditable"
                        rounded
                        text
                        class="text-caption"
                        @click="editableIndexUpdate(index)"
                    >
                      編集
                    </v-btn>
                    <v-btn
                        v-if="isCommentDeletable"
                        rounded
                        text
                        class="text-caption"
                        @click="deleteComment(index)"
                    >
                      削除
                    </v-btn>
                    <v-btn
                        text
                        disabled
                        class="text-caption grey--text"
                    >
                      <p class="pt-3  text--darken-1 grey--text" v-if="!!comment.edited_by">{{comment.edited_by }} に {{ dateTimeFormater(comment.edited_at)}} に編集されました</p>
                    </v-btn>
                  </v-card-actions>
                </v-card>
                <v-row no-gutters justify="end" v-if="index!=editableIndex">
                  <p class="text-sm-overline text--disabled mr-5">{{  dateTimeFormater(comment.created_at) }}</p>
                </v-row>

                <v-col cols="11" v-if="index==editableIndex">
                  <v-textarea
                      filled
                      outlined
                      :rules="commentRules"
                      v-model="commentEditText"
                      label="コメントを編集"
                  ></v-textarea>
                </v-col>


                <v-row no-gutters justify="center" class="pb-5 mt-n6" v-if="index==editableIndex">
                  <v-col cols="11">
                    <v-row
                        align="center"
                        justify="space-around"
                        class="px-4 mt-1"
                    >
                    <v-btn
                        small
                        depressed
                        color="error"
                        @click="editableIndexUpdate(-1)"
                    ><v-icon
                        right
                        dark
                        class="ml-0 mr-1"
                    >mdi-close
                    </v-icon>

                      キャンセル
                    </v-btn>
                      <v-spacer></v-spacer>
                    <v-btn
                        small
                        depressed
                        color="primary"
                        :disabled="!commentEditBtn"
                        :loading="commentEditLoading"
                        @click="editComment()"
                    ><v-icon
                        right
                        dark
                        class="ml-0 mr-1"
                    >mdi-playlist-edit
                    </v-icon>
                      コメントを編集する
                    </v-btn>
                    </v-row>
                  </v-col>

                </v-row>



              </v-row>
              </div>
            </v-card>
          </v-col>
<!--          comment part end        -->
        </v-row>
      </v-card>
  </v-dialog>
</template>

<script>
import BaButton from "@/components/BaButton";
import axios from "axios";
import api from "@/helper/api";
import S3Client from "@/helper/S3Client";
import routeNames from "@/router/routes/routeNames";
export default {
  name: "CommentDialog.vue",
  data() {
    return {
      addCommentElementsHeight:0,
      toolbarElementHeight:0,
      windowHeight:0,
      commentText: "",
      commentRules: [
        v => v.length <=4000|| '最大は４０００字です',
      ],
      commentBtn:false,
      commentLoading:false,
      direction: 'top',
      transition: 'slide-y-reverse-transition',
      fab: false,
      commentEditBtn:true,
      commentEditLoading:false,
      editableIndex: -1,
      commentEditText: null,
    }
  },
  components: {BaButton},
  props: {
    commentDialog: Object,
    commentBtnTxt: String,
    commentBtnColor: String,
    accountId: String,
    keysExtend: Array,
  },
  methods:{
    hideComment(){
      this.commentBtnColor="light-blue darken-2";
      this.commentBtnTxt="コメントを保存";
      this.commentText="";
      this.commentLoading=false;
      this.$emit('hide-comments')
    },
    addComment(){
      this.commentLoading=true
      axios.post(api.CREATE_ADMIN_COMMENT, {map_id: this.commentDialog.map_id, map_type: this.commentDialog.map_type, comment:this.commentText})
          .then((response) => {
            this.commentBtnTxt="コメントを保存";
            this.commentBtnColor="light-blue darken-2";
            this.commentLoading=false;
            let latestComments=[];
            let comment={};
            comment.comment=response.data.comment;
            comment.comment_id=response.data.comment_id;
            comment.created_at=response.data.created_at;
            comment.created_by=response.data.created_by;
            comment.edited_at=response.data.edited_at;
            comment.edited_by=response.data.edited_by;

            latestComments.push(comment)
            for(let i=0;i<this.commentDialog.comments.length;i++){
              latestComments.push(this.commentDialog.comments[i]);
            }
            this.commentDialog.comments=latestComments;
            this.commentText="";
            let val={
              number_of_comments:this.commentDialog.comments.length,
              map_id: this.commentDialog.map_id,
              map_type: this.commentDialog.map_type,
            }
            this.$emit('update-comments-number',val);
          })
          .catch(() => {
            this.commentLoading=false;
            this.commentBtnTxt="Retry!";
            this.commentBtnColor=" red darken-2";
          })
    },
    downloadReportPDFwithComment(){
      this.$store.dispatch('resultGenerateLd');
      this.commentDialog.filename=this.getFilename();
      let url = {
        name: routeNames.COMMENT_PDF.name,
        params: {
          data: this.commentDialog,
          user_details_data:{
            account_id: this.accountId,
            keys_extend: this.keysExtend,
          }
        }
      }
      this.$router.replace(url);
    },
    downloadReportPDFwithoutComment(){
      let s3Client = new S3Client(this.commentDialog.key)
      window.open(s3Client.getDownloadUrl())
    },
    editableIndexUpdate(index){
      this.editableIndex = index
      if(index!=-1){
        this.commentEditText = this.commentDialog.comments[index].comment
      }else{
        this.commentEditText = null
      }
    },
    editComment(){
      this.commentEditLoading = true
      this.commentEditBtn = false
      let commentID = this.commentDialog.comments[this.editableIndex].comment_id
      axios.post(api.UPDATE_ADMIN_COMMENT, {comment_id: commentID ,updated_comment:this.commentEditText, map_id: this.commentDialog.map_id, map_type: this.commentDialog.map_type})
          .then((response) => {
            this.commentDialog.comments[this.editableIndex].comment_id = response.data.comment_id
            this.commentDialog.comments[this.editableIndex].comment = response.data.comment
            this.commentDialog.comments[this.editableIndex].edited_by = response.data.edited_by
            this.commentDialog.comments[this.editableIndex].edited_at = response.data.edited_at

          })
          .catch(() => {
            this.$store.dispatch('updateDialog', "エラーがありました。管理者にご連絡ください。")
          }).finally(() =>{
        this.commentEditBtn = true
        this.commentEditLoading = false
            this.editableIndex = -1
            this.commentEditText = null
          })
    },
    deleteComment(index){
      if(confirm("削除してもよろしいでしょうか?")){
        let commentID = this.commentDialog.comments[index].comment_id
        axios.post(api.DELETE_ADMIN_COMMENT, {comment_id: commentID})
            .then(() => {
              this.commentDialog.comments.splice(index, 1)
              this.$store.dispatch('updateDialog', "コメントを削除しました。");
              let val={
                number_of_comments:this.commentDialog.comments.length,
                map_id: this.commentDialog.map_id,
                map_type: this.commentDialog.map_type,
              }
              this.$emit('update-comments-number',val);
            })
            .catch((response) => {
              this.$store.dispatch('updateDialog', response.data.error)
            });
      }
    },
    dateTimeFormater(dateVar){
      let dateTime = new Date(dateVar)
      return dateTime.getFullYear() + "年" +
          (dateTime.getMonth() + 1).toString().padStart(2, '0') + "月" +
          dateTime.getDate().toString().padStart(2, '0') + "日 " +
          dateTime.getHours().toString().padStart(2, '0') + ":" +
          dateTime.getMinutes().toString().padStart(2, '0') + ":" +
          dateTime.getSeconds().toString().padStart(2, '0');
    },
    getFilename() {
      let name= "";
      if(this.commentDialog.map_type==1) {
        name = "Admin"
            + "_SkillMap_with_Comment_" +
            new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate();
      }else if(this.commentDialog.map_type==2){
        name = "Admin"
            + "_ExperienceMap_with_Comment_" +
            new Date().getFullYear() + (new Date().getMonth() + 1) + new Date().getDate();
      }
      return name;
    },
    updateCommentListHeight(){
      this.windowHeight=window.innerHeight;
      if(this.$refs.add_comment) {
        this.addCommentElementsHeight = this.$refs.add_comment.clientHeight;
      }
      if(this.$refs.toolbar){
        this.toolbarElementHeight=this.$refs.toolbar.$el.clientHeight;
      }
    }
  },
  computed:{
    isDownloadWithCommentEnable(){
      if(this.commentDialog.has_comments){
        if(this.commentDialog.comments.length > 0){
          return false;
        }else{
          return true;
        }
      }else{
        if(this.commentDialog.comments.length > 0){
          return false;
        }else{
          return true;
        }
      }
    },
    getScreenHeight(){
      return screen.height*0.75
    },
    isCommentEnable(){
      return this.commentBtn;
    },
    isCommentAddable(){
      return this.commentDialog.is_addable
    },
    isCommentEditable(){
      return this.commentDialog.is_editable
    },
    isCommentDeletable(){
      return this.commentDialog.is_deletable
    },
    commentHeight(){
      return this.windowHeight-this.toolbarElementHeight-this.addCommentElementsHeight;
    }
  },
  watch: {
    commentText: function (val) {
      if (val.length > 0 && val.length <= 4000) {
        this.commentBtn = true;
      } else {
        this.commentBtn = false;
      }
    },

  },
  mounted() {
    window.addEventListener("resize",this.updateCommentListHeight);
  },
  updated() {
    this.windowHeight=window.innerHeight;
    if(this.$refs.add_comment) {
      this.addCommentElementsHeight = this.$refs.add_comment.clientHeight;
    }
    if(this.$refs.toolbar){
      this.toolbarElementHeight=this.$refs.toolbar.$el.clientHeight;
    }
  }
}
</script>

<style scoped>
.rounded-card{
  border-radius:15px 15px 15px 15px;
}
.pre-formatted {
  white-space: pre-line;
}
</style>