<template>
  <v-footer dark padless>
    <v-card class="flex" flat tile>
      <v-card-text class="grey lighten-4 text-left py-1 py-md-4 px-3 px-sm-3 px-md-4">
        <v-container class="grey lighten-4">
          <v-row no-gutters>
            <v-col cols=12 sm=12 md=3 lg=3 xl=3>
              <v-list class="grey lighten-4 pa-0">
                <v-list-item class="footer__munu__list pl-0 ">
                  <router-link :to="routeInfo.path">Skills-Scanとは？</router-link>
                </v-list-item>
                <v-list-item class="footer__munu__list pl-0 ">
                  <router-link :to="routeInfo.path">スキルチェック</router-link>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col cols=12 sm=12 md=9 lg=9 xl=9 class="mt-4 mt-sm-4 mt-md-0">
              <v-container class="grey lighten-4 pa-0">
                <v-row no-gutters>
                  <v-col cols=12 sm=12 md=4 lg=4 xl=4>
                    <v-container class="grey lighten-4 pa-0">
                      <v-row no-gutters>
                        <v-col cols=6 sm=6 md=12 lg=12 xl=12>
                          <v-list-item class="pl-0">
                            <router-link :to="routeInfo.path">プライバシーポリシー</router-link>
                          </v-list-item>
                        </v-col>
                        <v-col cols=6 sm=6 md=12 lg=12 xl=12>
                          <v-list-item class="pl-0">
                            <a href="https://www.b-architects.com/privacy/" target="_blank">個人情報保護方針</a>
                          </v-list-item>
                        </v-col>
                        <v-col cols=6 sm=6 md=12 lg=12 xl=12>
                          <v-list-item class="pl-0">
                            <router-link :to="routeInfo.path">個人情報の取り扱いについて</router-link>
                          </v-list-item>
                        </v-col>
                        <v-col cols=6 sm=6 md=12 lg=12 xl=12 class="d-flex d-sm-flex d-md-none">
                          <v-list-item class="pl-0">
                            <a href="https://www.b-architects.com/security/" target="_blank">情報セキュリティ方針</a>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols=12 sm=12 md=4 lg=4 xl=4>
                    <v-container class="grey lighten-4 pa-0">
                      <v-row no-gutters>
                        <v-col cols=6 sm=6 md=12 lg=12 xl=12 class="d-none d-sm-none d-md-flex">
                          <v-list-item class="pl-0">
                            <a href="https://www.b-architects.com/security/" target="_blank">情報セキュリティ方針</a>
                          </v-list-item>
                        </v-col>
                        <v-col cols=6 sm=6 md=12 lg=12 xl=12>
                          <v-list-item class="pl-0">
                            <router-link :to="routeInfo.path">サイトご利用にあたって</router-link>
                          </v-list-item>
                        </v-col>
                        <v-col cols=6 sm=6 md=12 lg=12 xl=12>
                          <v-list-item class="pl-0">
                            <router-link :to="routeInfo.path">利用規約</router-link>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols=12 sm=12 md=4 lg=4 xl=4>
                    <v-container class="grey lighten-4 pa-0">
                      <v-row no-gutters>
                        <v-col cols=6 sm=6 md=12 lg=12 xl=12>
                          <v-list-item class="pl-0">
                            <router-link :to="routeInfo.path">お問い合わせ</router-link>
                          </v-list-item>
                        </v-col>
                        <v-col cols=6 sm=6 md=12 lg=12 xl=12>
                          <v-list-item class="pl-0">
                            <router-link :to="routeInfo.path">運営会社</router-link>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-text class="px-3 px-sm-3 px-md-4 py-0 white--text">
        <v-container class="py-4">
          <v-row class="">
            <v-col cols="auto" class="text-left">
              <v-img
                  :src="require('../assets/BA-logo.svg')"
                  class="my-1"
                  contain
                  height="35"
                  width="50"
              />
            </v-col>
            <v-col cols="auto" class="text-left">
              株式会社 ビジネス・アーキテクツ<br>
              職業紹介事業者 許可番号 ： 13-ユ-311641
            </v-col>
            <v-col md="col-auto" class="text-left textsm-left text-md-right pt-6">
              © {{ new Date().getFullYear() }} <span>Business Architects Inc.</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <!-- ▼Change Language Button▼ -->
      <!--
      <v-card-text class="grey--text lighten-1">
        {{ $vuetify.lang.t('$vuetify.footer.language') }}: <a @click="changeLang">{{ this.language }}</a>
      </v-card-text>
      -->
      <!-- ▲Change Language Button▲ -->
    </v-card>
  </v-footer>
</template>

<script>
import routes from "@/router/routes/routeNames"

export default {
  data() {
    return {
      language: "",
      icons: [
        '../assets/icon-sns_insta.svg',
        '../assets/icon-sns_twitter.svg',
        '../assets/icon-sns_fb.svg',
      ],
      routeInfo:{
        name: routes.USER_MANAGEMENT.name,
        path: routes.USER_MANAGEMENT.path
      }
    }
  }
}
</script>

<style scoped>
.l-subtitle {
  font-size: 14px;
  padding: 4px 16px 0px 16px;
  border-left: 4px solid #FBC02D;
}
/* ▼Vuetify existing class▼ */
.v-list-item {
  min-height: 36px;
}
.v-list-item  a {
  color: #222222;
  text-decoration: none;
}
.theme--dark.v-footer {
  background-color: #222222;
}
.col.col-auto {
  font-size: 12px;
}
.v-card a {
  color: #222222;
}
@media only screen and (min-width: 960px) {
  .container {
    max-width: 1064px !important;
  }
}
/* ▲Vuetify existing class▲ */

@media only screen and (max-width: 959px) {
  .footer__munu__list {
    font-weight: bold;
    padding: 8px 0;
    border-bottom: 1px solid #cccccc;
  }
}
</style>